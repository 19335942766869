import { install } from "@twind/core";
import Alpine from "alpinejs";

import config from "../../../twind.config";
import bootstrapAlpine from "./bootstrap";

import "../../css/styles.scss";

install(config);
bootstrapAlpine();
Alpine.start();

document.addEventListener("DOMContentLoaded", () => {
    const masterZone = document.querySelector("#master-zone");
    if (masterZone) {
        (masterZone as HTMLElement).style.display = "block";
    }
    const signInOverlay = document.querySelector("#sign-in-overlay");
    if (signInOverlay) {
        (signInOverlay as HTMLElement).style.display = "block";
    }
});
