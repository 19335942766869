[
    {
        "code": "AF",
        "country": "Afghanistan",
        "access": "FALSE"
    },
    {
        "code": "AX",
        "country": "Åland Islands",
        "access": "FALSE"
    },
    {
        "code": "AL",
        "country": "Albania",
        "access": "TRUE"
    },
    {
        "code": "DZ",
        "country": "Algeria",
        "access": "FALSE"
    },
    {
        "code": "AS",
        "country": "American Samoa",
        "access": "FALSE"
    },
    {
        "code": "AD",
        "country": "Andorra",
        "access": "TRUE"
    },
    {
        "code": "AO",
        "country": "Angola",
        "access": "FALSE"
    },
    {
        "code": "AI",
        "country": "Anguilla",
        "access": "FALSE"
    },
    {
        "code": "AQ",
        "country": "Antarctica",
        "access": "FALSE"
    },
    {
        "code": "AG",
        "country": "Antigua and Barbuda",
        "access": "FALSE"
    },
    {
        "code": "AR",
        "country": "Argentina",
        "access": "FALSE"
    },
    {
        "code": "AM",
        "country": "Armenia",
        "access": "TRUE"
    },
    {
        "code": "AW",
        "country": "Aruba",
        "access": "FALSE"
    },
    {
        "code": "AU",
        "country": "Australia",
        "access": "TRUE"
    },
    {
        "code": "AT",
        "country": "Austria",
        "access": "TRUE"
    },
    {
        "code": "AZ",
        "country": "Azerbaijan",
        "access": "FALSE"
    },
    {
        "code": "BS",
        "country": "Bahamas",
        "access": "FALSE"
    },
    {
        "code": "BH",
        "country": "Bahrain",
        "access": "TRUE"
    },
    {
        "code": "BD",
        "country": "Bangladesh",
        "access": "FALSE"
    },
    {
        "code": "BB",
        "country": "Barbados",
        "access": "FALSE"
    },
    {
        "code": "BY",
        "country": "Belarus",
        "access": "FALSE"
    },
    {
        "code": "BE",
        "country": "Belgium",
        "access": "TRUE"
    },
    {
        "code": "BZ",
        "country": "Belize",
        "access": "FALSE"
    },
    {
        "code": "BJ",
        "country": "Benin",
        "access": "FALSE"
    },
    {
        "code": "BM",
        "country": "Bermuda",
        "access": "FALSE"
    },
    {
        "code": "BT",
        "country": "Bhutan",
        "access": "FALSE"
    },
    {
        "code": "BO",
        "country": "Bolivia",
        "access": "FALSE"
    },
    {
        "code": "BQ",
        "country": "Bonaire,  Sint Eustatius and Saba",
        "access": "FALSE"
    },
    {
        "code": "BA",
        "country": "Bosnia and Herzegovina",
        "access": "TRUE"
    },
    {
        "code": "BW",
        "country": "Botswana",
        "access": "FALSE"
    },
    {
        "code": "BV",
        "country": "Bouvet Island",
        "access": "FALSE"
    },
    {
        "code": "BR",
        "country": "Brazil",
        "access": "FALSE"
    },
    {
        "code": "IO",
        "country": "British Indian Ocean Territory",
        "access": "FALSE"
    },
    {
        "code": "BN",
        "country": "Brunei",
        "access": "FALSE"
    },
    {
        "code": "BG",
        "country": "Bulgaria",
        "access": "TRUE"
    },
    {
        "code": "BF",
        "country": "Burkina Faso",
        "access": "FALSE"
    },
    {
        "code": "BI",
        "country": "Burundi",
        "access": "FALSE"
    },
    {
        "code": "CV",
        "country": "Cabo Verde",
        "access": "FALSE"
    },
    {
        "code": "KH",
        "country": "Cambodia",
        "access": "FALSE"
    },
    {
        "code": "CM",
        "country": "Cameroon",
        "access": "FALSE"
    },
    {
        "code": "CA",
        "country": "Canada",
        "access": "TRUE"
    },
    {
        "code": "KY",
        "country": "Cayman Islands",
        "access": "FALSE"
    },
    {
        "code": "CF",
        "country": "Central African Republic",
        "access": "FALSE"
    },
    {
        "code": "TD",
        "country": "Chad",
        "access": "FALSE"
    },
    {
        "code": "CL",
        "country": "Chile",
        "access": "FALSE"
    },
    {
        "code": "CN",
        "country": "China",
        "access": "FALSE"
    },
    {
        "code": "CX",
        "country": "Christmas Island",
        "access": "FALSE"
    },
    {
        "code": "CC",
        "country": "Cocos (Keeling) Islands",
        "access": "FALSE"
    },
    {
        "code": "CO",
        "country": "Colombia",
        "access": "FALSE"
    },
    {
        "code": "KM",
        "country": "Comoros",
        "access": "FALSE"
    },
    {
        "code": "CG",
        "country": "Congo",
        "access": "FALSE"
    },
    {
        "code": "CD",
        "country": "Congo (the Democratic Republic of the)",
        "access": "FALSE"
    },
    {
        "code": "CK",
        "country": "Cook Islands",
        "access": "FALSE"
    },
    {
        "code": "CR",
        "country": "Costa Rica",
        "access": "FALSE"
    },
    {
        "code": "CI",
        "country": "Côte d'Ivoire",
        "access": "FALSE"
    },
    {
        "code": "HR",
        "country": "Croatia",
        "access": "TRUE"
    },
    {
        "code": "CU",
        "country": "Cuba",
        "access": "FALSE"
    },
    {
        "code": "CW",
        "country": "Curaçao",
        "access": "FALSE"
    },
    {
        "code": "CY",
        "country": "Cyprus",
        "access": "TRUE"
    },
    {
        "code": "CZ",
        "country": "Czechia",
        "access": "TRUE"
    },
    {
        "code": "DK",
        "country": "Denmark",
        "access": "TRUE"
    },
    {
        "code": "DJ",
        "country": "Djibouti",
        "access": "FALSE"
    },
    {
        "code": "DM",
        "country": "Dominica",
        "access": "FALSE"
    },
    {
        "code": "DO",
        "country": "Dominican Republic",
        "access": "FALSE"
    },
    {
        "code": "EC",
        "country": "Ecuador",
        "access": "FALSE"
    },
    {
        "code": "EG",
        "country": "Egypt",
        "access": "FALSE"
    },
    {
        "code": "SV",
        "country": "El Salvador",
        "access": "FALSE"
    },
    {
        "code": "GQ",
        "country": "Equatorial Guinea",
        "access": "FALSE"
    },
    {
        "code": "ER",
        "country": "Eritrea",
        "access": "FALSE"
    },
    {
        "code": "EE",
        "country": "Estonia",
        "access": "TRUE"
    },
    {
        "code": "SZ",
        "country": "Eswatini",
        "access": "FALSE"
    },
    {
        "code": "ET",
        "country": "Ethiopia",
        "access": "FALSE"
    },
    {
        "code": "FK",
        "country": "Falkland Islands (Malvinas)",
        "access": "FALSE"
    },
    {
        "code": "FO",
        "country": "Faroe Islands",
        "access": "TRUE"
    },
    {
        "code": "FJ",
        "country": "Fiji",
        "access": "FALSE"
    },
    {
        "code": "FI",
        "country": "Finland",
        "access": "TRUE"
    },
    {
        "code": "FR",
        "country": "France",
        "access": "TRUE"
    },
    {
        "code": "GF",
        "country": "French Guiana",
        "access": "FALSE"
    },
    {
        "code": "PF",
        "country": "French Polynesia",
        "access": "FALSE"
    },
    {
        "code": "TF",
        "country": "French Southern Territories",
        "access": "FALSE"
    },
    {
        "code": "GA",
        "country": "Gabon",
        "access": "FALSE"
    },
    {
        "code": "GM",
        "country": "Gambia",
        "access": "FALSE"
    },
    {
        "code": "GE",
        "country": "Georgia",
        "access": "FALSE"
    },
    {
        "code": "DE",
        "country": "Germany",
        "access": "TRUE"
    },
    {
        "code": "GH",
        "country": "Ghana",
        "access": "FALSE"
    },
    {
        "code": "GI",
        "country": "Gibraltar",
        "access": "TRUE"
    },
    {
        "code": "GR",
        "country": "Greece",
        "access": "TRUE"
    },
    {
        "code": "GL",
        "country": "Greenland",
        "access": "TRUE"
    },
    {
        "code": "GD",
        "country": "Grenada",
        "access": "FALSE"
    },
    {
        "code": "GP",
        "country": "Guadeloupe",
        "access": "FALSE"
    },
    {
        "code": "GU",
        "country": "Guam",
        "access": "FALSE"
    },
    {
        "code": "GT",
        "country": "Guatemala",
        "access": "FALSE"
    },
    {
        "code": "GG",
        "country": "Guernsey",
        "access": "TRUE"
    },
    {
        "code": "GN",
        "country": "Guinea",
        "access": "FALSE"
    },
    {
        "code": "GW",
        "country": "Guinea-Bissau",
        "access": "FALSE"
    },
    {
        "code": "GY",
        "country": "Guyana",
        "access": "FALSE"
    },
    {
        "code": "HT",
        "country": "Haiti",
        "access": "FALSE"
    },
    {
        "code": "HM",
        "country": "Heard Island and McDonald Islands",
        "access": "FALSE"
    },
    {
        "code": "VA",
        "country": "Holy See",
        "access": "TRUE"
    },
    {
        "code": "HN",
        "country": "Honduras",
        "access": "FALSE"
    },
    {
        "code": "HK",
        "country": "Hong Kong",
        "access": "TRUE"
    },
    {
        "code": "HU",
        "country": "Hungary",
        "access": "TRUE"
    },
    {
        "code": "IS",
        "country": "Iceland",
        "access": "TRUE"
    },
    {
        "code": "IN",
        "country": "India",
        "access": "FALSE"
    },
    {
        "code": "ID",
        "country": "Indonesia",
        "access": "FALSE"
    },
    {
        "code": "IR",
        "country": "Iran",
        "access": "FALSE"
    },
    {
        "code": "IQ",
        "country": "Iraq",
        "access": "FALSE"
    },
    {
        "code": "IE",
        "country": "Ireland",
        "access": "TRUE"
    },
    {
        "code": "IM",
        "country": "Isle of Man",
        "access": "TRUE"
    },
    {
        "code": "IL",
        "country": "Israel",
        "access": "TRUE"
    },
    {
        "code": "IT",
        "country": "Italy",
        "access": "TRUE"
    },
    {
        "code": "JM",
        "country": "Jamaica",
        "access": "FALSE"
    },
    {
        "code": "JP",
        "country": "Japan",
        "access": "FALSE"
    },
    {
        "code": "JE",
        "country": "Jersey",
        "access": "TRUE"
    },
    {
        "code": "JO",
        "country": "Jordan",
        "access": "FALSE"
    },
    {
        "code": "KZ",
        "country": "Kazakhstan",
        "access": "FALSE"
    },
    {
        "code": "KE",
        "country": "Kenya",
        "access": "FALSE"
    },
    {
        "code": "KI",
        "country": "Kiribati",
        "access": "FALSE"
    },
    {
        "code": "KW",
        "country": "Kuwait",
        "access": "FALSE"
    },
    {
        "code": "KG",
        "country": "Kyrgyzstan",
        "access": "FALSE"
    },
    {
        "code": "LA",
        "country": "Laos",
        "access": "FALSE"
    },
    {
        "code": "LV",
        "country": "Latvia",
        "access": "TRUE"
    },
    {
        "code": "LB",
        "country": "Lebanon",
        "access": "FALSE"
    },
    {
        "code": "LS",
        "country": "Lesotho",
        "access": "FALSE"
    },
    {
        "code": "LR",
        "country": "Liberia",
        "access": "FALSE"
    },
    {
        "code": "LY",
        "country": "Libya",
        "access": "FALSE"
    },
    {
        "code": "LI",
        "country": "Liechtenstein",
        "access": "TRUE"
    },
    {
        "code": "LT",
        "country": "Lithuania",
        "access": "TRUE"
    },
    {
        "code": "LU",
        "country": "Luxembourg",
        "access": "TRUE"
    },
    {
        "code": "MO",
        "country": "Macao",
        "access": "FALSE"
    },
    {
        "code": "MG",
        "country": "Madagascar",
        "access": "FALSE"
    },
    {
        "code": "MW",
        "country": "Malawi",
        "access": "FALSE"
    },
    {
        "code": "MY",
        "country": "Malaysia",
        "access": "FALSE"
    },
    {
        "code": "MV",
        "country": "Maldives",
        "access": "FALSE"
    },
    {
        "code": "ML",
        "country": "Mali",
        "access": "FALSE"
    },
    {
        "code": "MT",
        "country": "Malta",
        "access": "TRUE"
    },
    {
        "code": "MH",
        "country": "Marshall Islands",
        "access": "FALSE"
    },
    {
        "code": "MQ",
        "country": "Martinique",
        "access": "FALSE"
    },
    {
        "code": "MR",
        "country": "Mauritania",
        "access": "FALSE"
    },
    {
        "code": "MU",
        "country": "Mauritius",
        "access": "FALSE"
    },
    {
        "code": "YT",
        "country": "Mayotte",
        "access": "FALSE"
    },
    {
        "code": "MX",
        "country": "Mexico",
        "access": "FALSE"
    },
    {
        "code": "FM",
        "country": "Micronesia (Federated States of)",
        "access": "FALSE"
    },
    {
        "code": "MD",
        "country": "Moldova",
        "access": "FALSE"
    },
    {
        "code": "MC",
        "country": "Monaco",
        "access": "TRUE"
    },
    {
        "code": "MN",
        "country": "Mongolia",
        "access": "FALSE"
    },
    {
        "code": "ME",
        "country": "Montenegro",
        "access": "TRUE"
    },
    {
        "code": "MS",
        "country": "Montserrat",
        "access": "TRUE"
    },
    {
        "code": "MA",
        "country": "Morocco",
        "access": "FALSE"
    },
    {
        "code": "MZ",
        "country": "Mozambique",
        "access": "FALSE"
    },
    {
        "code": "MM",
        "country": "Myanmar",
        "access": "FALSE"
    },
    {
        "code": "NA",
        "country": "Namibia",
        "access": "FALSE"
    },
    {
        "code": "NR",
        "country": "Nauru",
        "access": "FALSE"
    },
    {
        "code": "NP",
        "country": "Nepal",
        "access": "FALSE"
    },
    {
        "code": "NL",
        "country": "Netherlands",
        "access": "TRUE"
    },
    {
        "code": "NC",
        "country": "New Caledonia",
        "access": "FALSE"
    },
    {
        "code": "NZ",
        "country": "New Zealand",
        "access": "TRUE"
    },
    {
        "code": "NI",
        "country": "Nicaragua",
        "access": "FALSE"
    },
    {
        "code": "NE",
        "country": "Niger",
        "access": "FALSE"
    },
    {
        "code": "NG",
        "country": "Nigeria",
        "access": "FALSE"
    },
    {
        "code": "NU",
        "country": "Niue",
        "access": "FALSE"
    },
    {
        "code": "NF",
        "country": "Norfolk Island",
        "access": "FALSE"
    },
    {
        "code": "KP",
        "country": "North Korea",
        "access": "FALSE"
    },
    {
        "code": "MK",
        "country": "North Macedonia",
        "access": "FALSE"
    },
    {
        "code": "MP",
        "country": "Northern Mariana Islands",
        "access": "FALSE"
    },
    {
        "code": "NO",
        "country": "Norway",
        "access": "TRUE"
    },
    {
        "code": "OM",
        "country": "Oman",
        "access": "FALSE"
    },
    {
        "code": "PK",
        "country": "Pakistan",
        "access": "FALSE"
    },
    {
        "code": "PW",
        "country": "Palau",
        "access": "FALSE"
    },
    {
        "code": "PS",
        "country": "Palestine,  State of",
        "access": "FALSE"
    },
    {
        "code": "PA",
        "country": "Panama",
        "access": "FALSE"
    },
    {
        "code": "PG",
        "country": "Papua New Guinea",
        "access": "FALSE"
    },
    {
        "code": "PY",
        "country": "Paraguay",
        "access": "FALSE"
    },
    {
        "code": "PE",
        "country": "Peru",
        "access": "FALSE"
    },
    {
        "code": "PH",
        "country": "Philippines",
        "access": "FALSE"
    },
    {
        "code": "PN",
        "country": "Pitcairn",
        "access": "FALSE"
    },
    {
        "code": "PL",
        "country": "Poland",
        "access": "TRUE"
    },
    {
        "code": "PT",
        "country": "Portugal",
        "access": "TRUE"
    },
    {
        "code": "PR",
        "country": "Puerto Rico",
        "access": "FALSE"
    },
    {
        "code": "QA",
        "country": "Qatar",
        "access": "TRUE"
    },
    {
        "code": "RE",
        "country": "Réunion",
        "access": "FALSE"
    },
    {
        "code": "RO",
        "country": "Romania",
        "access": "TRUE"
    },
    {
        "code": "RU",
        "country": "Russia",
        "access": "FALSE"
    },
    {
        "code": "RW",
        "country": "Rwanda",
        "access": "FALSE"
    },
    {
        "code": "BL",
        "country": "Saint Barthélemy",
        "access": "FALSE"
    },
    {
        "code": "SH",
        "country": "Saint Helena,  Ascension and Tristan da Cunha",
        "access": "FALSE"
    },
    {
        "code": "KN",
        "country": "Saint Kitts and Nevis",
        "access": "FALSE"
    },
    {
        "code": "LC",
        "country": "Saint Lucia",
        "access": "FALSE"
    },
    {
        "code": "MF",
        "country": "Saint Martin (French part)",
        "access": "FALSE"
    },
    {
        "code": "PM",
        "country": "Saint Pierre and Miquelon",
        "access": "FALSE"
    },
    {
        "code": "VC",
        "country": "Saint Vincent and the Grenadines",
        "access": "FALSE"
    },
    {
        "code": "WS",
        "country": "Samoa",
        "access": "FALSE"
    },
    {
        "code": "SM",
        "country": "San Marino",
        "access": "FALSE"
    },
    {
        "code": "ST",
        "country": "Sao Tome and Principe",
        "access": "FALSE"
    },
    {
        "code": "SA",
        "country": "Saudi Arabia",
        "access": "FALSE"
    },
    {
        "code": "SN",
        "country": "Senegal",
        "access": "FALSE"
    },
    {
        "code": "RS",
        "country": "Serbia",
        "access": "TRUE"
    },
    {
        "code": "SC",
        "country": "Seychelles",
        "access": "FALSE"
    },
    {
        "code": "SL",
        "country": "Sierra Leone",
        "access": "FALSE"
    },
    {
        "code": "SG",
        "country": "Singapore",
        "access": "TRUE"
    },
    {
        "code": "SX",
        "country": "Sint Maarten (Dutch part)",
        "access": "FALSE"
    },
    {
        "code": "SK",
        "country": "Slovakia",
        "access": "TRUE"
    },
    {
        "code": "SI",
        "country": "Slovenia",
        "access": "TRUE"
    },
    {
        "code": "SB",
        "country": "Solomon Islands",
        "access": "FALSE"
    },
    {
        "code": "SO",
        "country": "Somalia",
        "access": "FALSE"
    },
    {
        "code": "ZA",
        "country": "South Africa",
        "access": "TRUE"
    },
    {
        "code": "GS",
        "country": "South Georgia and the South Sandwich Islands",
        "access": "FALSE"
    },
    {
        "code": "KR",
        "country": "South Korea",
        "access": "TRUE"
    },
    {
        "code": "SS",
        "country": "South Sudan",
        "access": "FALSE"
    },
    {
        "code": "ES",
        "country": "Spain",
        "access": "TRUE"
    },
    {
        "code": "LK",
        "country": "Sri Lanka",
        "access": "FALSE"
    },
    {
        "code": "SD",
        "country": "Sudan",
        "access": "FALSE"
    },
    {
        "code": "SR",
        "country": "Suriname",
        "access": "FALSE"
    },
    {
        "code": "SJ",
        "country": "Svalbard and Jan Mayen",
        "access": "TRUE"
    },
    {
        "code": "SE",
        "country": "Sweden",
        "access": "TRUE"
    },
    {
        "code": "CH",
        "country": "Switzerland",
        "access": "TRUE"
    },
    {
        "code": "SY",
        "country": "Syria",
        "access": "FALSE"
    },
    {
        "code": "TW",
        "country": "Taiwan",
        "access": "FALSE"
    },
    {
        "code": "TJ",
        "country": "Tajikistan",
        "access": "FALSE"
    },
    {
        "code": "TZ",
        "country": "Tanzania",
        "access": "FALSE"
    },
    {
        "code": "TH",
        "country": "Thailand",
        "access": "FALSE"
    },
    {
        "code": "TL",
        "country": "Timor-Leste",
        "access": "FALSE"
    },
    {
        "code": "TG",
        "country": "Togo",
        "access": "FALSE"
    },
    {
        "code": "TK",
        "country": "Tokelau",
        "access": "FALSE"
    },
    {
        "code": "TO",
        "country": "Tonga",
        "access": "FALSE"
    },
    {
        "code": "TT",
        "country": "Trinidad and Tobago",
        "access": "FALSE"
    },
    {
        "code": "TN",
        "country": "Tunisia",
        "access": "FALSE"
    },
    {
        "code": "TR",
        "country": "Turkey",
        "access": "FALSE"
    },
    {
        "code": "TM",
        "country": "Turkmenistan",
        "access": "FALSE"
    },
    {
        "code": "TC",
        "country": "Turks and Caicos Islands",
        "access": "FALSE"
    },
    {
        "code": "TV",
        "country": "Tuvalu",
        "access": "FALSE"
    },
    {
        "code": "UG",
        "country": "Uganda",
        "access": "FALSE"
    },
    {
        "code": "UA",
        "country": "Ukraine",
        "access": "FALSE"
    },
    {
        "code": "AE",
        "country": "United Arab Emirates",
        "access": "TRUE"
    },
    {
        "code": "GB",
        "country": "United Kingdom",
        "access": "TRUE"
    },
    {
        "code": "UM",
        "country": "United States Minor Outlying Islands",
        "access": "FALSE"
    },
    {
        "code": "US",
        "country": "United States of America",
        "access": "FALSE"
    },
    {
        "code": "UY",
        "country": "Uruguay",
        "access": "FALSE"
    },
    {
        "code": "UZ",
        "country": "Uzbekistan",
        "access": "FALSE"
    },
    {
        "code": "VU",
        "country": "Vanuatu",
        "access": "FALSE"
    },
    {
        "code": "VE",
        "country": "Venezuela",
        "access": "FALSE"
    },
    {
        "code": "VN",
        "country": "Vietnam",
        "access": "FALSE"
    },
    {
        "code": "VG",
        "country": "Virgin Islands (British)",
        "access": "TRUE"
    },
    {
        "code": "VI",
        "country": "Virgin Islands (U.S.)",
        "access": "FALSE"
    },
    {
        "code": "WF",
        "country": "Wallis and Futuna",
        "access": "FALSE"
    },
    {
        "code": "EH",
        "country": "Western Sahara",
        "access": "FALSE"
    },
    {
        "code": "YE",
        "country": "Yemen",
        "access": "FALSE"
    },
    {
        "code": "ZM",
        "country": "Zambia",
        "access": "FALSE"
    },
    {
        "code": "ZW",
        "country": "Zimbabwe",
        "access": "FALSE"
    }
]
