function mobileScrollX() {
    document.addEventListener("DOMContentLoaded", () => {
        const xScrollComponents = document.querySelectorAll(".mobile-scroll-x");
        xScrollComponents.forEach((component) => {
            const parentElem = component.parentElement.firstChild;
            component.addEventListener("click", () => {
                parentElem.scrollBy({
                    left: parentElem.firstChild.getBoundingClientRect().width,
                    behaviour: "smooth",
                });
            });
        });
    });
}

export default mobileScrollX;
