import Alpine from "alpinejs";

import contactHome from "./components/contactHome";
import cookies from "./components/cookies";
import mobileScrollX from "./components/mobileScrollX";
import PopOutImages from "./components/popOutImages";
import showMorePostButton from "./components/showMorePostButton";
import signIn from "./components/signIn";

export default function bootstrapAlpine() {
    // Alpine.data("signIn", signIn);
    signIn();
    cookies();
    contactHome();
    showMorePostButton();
    mobileScrollX();
    PopOutImages();
}
