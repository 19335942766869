import Alpine from "alpinejs";

export default function PopOutImages() {
    const isDesktop = () => matchMedia("(min-width: 1024px)").matches;
    return Alpine.data("popoutHandler", () => ({
        isModalOpen: false,
        modalContent: "",
        openModal(content) {
            this.modalContent = content;
            this.isModalOpen = true;
            this.addEscapeListener();
        },
        closeModal() {
            this.isModalOpen = false;
            this.modalContent = "";
            this.removeEscapeListener();
        },
        handleClick(event) {
            const image = event.target.closest("img");

            const parent = image.parentElement;
            const imgW = image.naturalWidth;
            const parentW = parent?.getBoundingClientRect().width ?? 0;
            if (imgW > parentW + 50 && isDesktop()) {
                const clone = image.cloneNode();
                clone.style.cursor = "default";
                this.openModal(clone.outerHTML);
            }
        },
        handleMouseOver(event) {
            const image = event.target.closest("img");
            const parent = image.parentElement;
            const imgW = image.naturalWidth;
            const parentW = parent?.getBoundingClientRect().width ?? 0;
            if (imgW > parentW + 50 && isDesktop()) {
                image.style.cursor = "zoom-in";
            } else {
                image.style.cursor = "default";
            }
        },
        addEscapeListener() {
            const handler = (event) => {
                if (event.key === "Escape") {
                    this.closeModal();
                }
            };
            window.addEventListener("keydown", handler);
            this.escapeHandler = handler;
        },
        removeEscapeListener() {
            if (this.escapeHandler) {
                window.removeEventListener("keydown", this.escapeHandler);
                this.escapeHandler = null;
            }
        },
        escapeHandler: null,
    }));
}
