import Alpine from "alpinejs";

function showMorePostButton() {
    Alpine.store("showMorePostButton", {
        showButton: false,
        isExpanded: window.innerWidth > 600 ? true : false,

        checkAndLogDescriptionHeight() {
            setTimeout(() => {
                const descriptionDiv = document.querySelector(".description");
                const allChildElements = Array.from(descriptionDiv.children)
                    .map((childNode) => {
                        if (childNode.innerText.length <= 1) {
                            return null;
                        }
                        return childNode;
                    })
                    .filter((child) => child !== null);

                descriptionDiv.innerHTML = allChildElements
                    .map((child) => child.outerHTML)
                    .join("");

                const lineHeight = window
                    .getComputedStyle(descriptionDiv.firstChild)
                    .getPropertyValue("line-height")
                    .replace(/\D/g, "");

                const divHeight = window
                    .getComputedStyle(descriptionDiv)
                    .getPropertyValue("height")
                    .replace(/\D/g, "");

                if (divHeight / lineHeight > 10) {
                    descriptionDiv.style.maxHeight = 10 * lineHeight + "px";

                    this.showButton = true;
                }
            }, 100);
        },
    });
}

export default showMorePostButton;
