import Alpine from "alpinejs";

export default function contactHome() {
    const peopleCardContainer = document.querySelector("#peopleCardContainer");

    if (!peopleCardContainer) return null;

    const peopleCards = peopleCardContainer.querySelectorAll(".personCard");

    Alpine.store("contactHome", {
        activeCardIndex: 0,
        numPeople: peopleCards.length,
        get nextDisabled() {
            return this.activeCardIndex == this.numPeople - 1;
        },
        get prevDisabled() {
            return this.activeCardIndex == 0;
        },
        nextCard() {
            console.log(
                "%ccontactHome.js line:12 next",
                "color: #007acc;",
                this.activeCardIndex,
                this.numPeople,
            );
            if (this.activeCardIndex < this.numPeople - 1) {
                ++this.activeCardIndex;
            }
        },
        prevCard() {
            console.log(
                "%ccontactHome.js line:12 prev",
                "color: #007acc;",
                this.activeCardIndex,
            );

            if (this.activeCardIndex > 0) {
                --this.activeCardIndex;
            }
        },
    });
}
