/* eslint-disable */
import Alpine from "alpinejs";

import mockData from "../../../data/audience_select_mock_response.json";
import countries from "../../../data/countries.json";

export default function signIn() {
    const jurisdictionId =
        document.querySelector("body").dataset.jurisdictionId;

    const allJurisdictions = mockData.groups.flatMap(
        (group) => group.jurisdictions,
    );

    const initialJurisdiction = allJurisdictions.find(
        (jurisdiction) => jurisdiction.id == jurisdictionId,
    );

    const csrfmiddlewaretoken = document.querySelector(
        "#csrftoken > input[type=hidden]",
    )?.value;

    const textPiecesContent = document.querySelector(
        "#audience-select-text-pieces",
    )?.innerText;
    const textPieces = textPiecesContent ? JSON.parse(textPiecesContent) : {};

    const OTHER_COUNTRIES = mockData.groups[6].jurisdictions
        .flatMap((jr) =>
            jr.countries.map((code) => {
                const countryName = countries.find(
                    (country) => country.code === code,
                ).country;
                return {
                    ...jr,
                    display_name: countryName,
                };
            }),
        )
        .sort((a, b) => (a.display_name > b.display_name ? 1 : -1));

    Alpine.store("signIn", {
        view: initialJurisdiction
            ? initialJurisdiction.id === 269
                ? "notAvailableInUSA"
                : "audience"
            : "jurisdiction",
        uk: mockData.groups[0].jurisdictions[0],
        primary: mockData.groups[1].jurisdictions,
        secondary: mockData.groups[2].jurisdictions,
        otherEU: mockData.groups[3].jurisdictions[0],
        us: mockData.groups[4].jurisdictions[0],
        channelIslands: mockData.groups[5].jurisdictions[0],
        dropdownOpen: false,
        dropdownSearch: "",
        activeJurisdiction: initialJurisdiction ?? undefined,
        activeAudience: undefined,
        get header() {
            switch (this.view) {
                case "jurisdiction":
                    return "Select your country";
                case "audience":
                    return "Select your Investor Type";
                case "notAvailableInUSA":
                case "notAvailableInJurisdiction":
                    return `The information on this site is not available to investors in ${this.activeJurisdiction.display_name}`;
                case "usertypeNotAllowed":
                    return `The information on this site is not available to individual investors`;
                case "confirmTCs":
                    return "Confirm your agreement to gain access to our website";
                default:
                    break;
            }
        },
        get text() {
            switch (this.view) {
                case "jurisdiction":
                    return "Select your country";
                case "audience":
                    return "Select your Investor Type";
                case "notAvailableInUSA":
                    return textPieces.audience_us.default.html;
                case "notAvailableInJurisdiction":
                    return this.findTextPieceForJurisdiction(
                        textPieces.audience_international,
                    );
                case "usertypeNotAllowed":
                    return this.findTextPieceForJurisdiction(
                        textPieces.audience_private,
                    );
                case "confirmTCs":
                    return this.findTextPieceForAudience(
                        textPieces.terms_and_conditions,
                    );
                default:
                    break;
            }
        },
        get searchResults() {
            if (this.dropdownSearch === "") {
                return OTHER_COUNTRIES;
            }
            return OTHER_COUNTRIES.filter((x) =>
                x.display_name
                    .toLowerCase()
                    .startsWith(this.dropdownSearch.toLowerCase()),
            );
        },
        findTextPieceForJurisdiction(textPieceObj) {
            if (!this.activeJurisdiction) {
                return textPieceObj.default.html;
            }
            return (
                Object.values(textPieceObj).find((tnc) =>
                    tnc.jurisdiction_ids.includes(this.activeJurisdiction.id),
                )?.html ?? textPieceObj.default.html
            );
        },
        findTextPieceForAudience(textPieceObj) {
            if (!this.activeAudience) {
                return textPieceObj.default.html;
            }
            return (
                Object.values(textPieceObj).find((tnc) =>
                    tnc.audience_ids.includes(this.activeAudience.id),
                )?.html ?? textPieceObj.default.html
            );
        },
        handleChangeCountry() {
            this.view = "jurisdiction";
            this.activeJurisdiction = undefined;
            this.activeAudience = undefined;
        },
        handleChangeInvestorType() {
            this.view = "audience";
            this.activeAudience = undefined;
        },
        toggleDropdownOpen(bool) {
            if (typeof bool === "boolean") {
                this.dropdownOpen = bool;
            } else {
                this.dropdownOpen = !this.dropdownOpen;
            }
        },
        handleCountryClick(jurisdiction) {
            this.activeJurisdiction = jurisdiction;
            this.dropdownOpen = false;
            // No audiences === jurisdiction not allowed
            if (jurisdiction.id === 269) {
                this.view = "notAvailableInUSA";
            } else if (jurisdiction.audiences.length === 0) {
                this.view = "notAvailableInJurisdiction";
            } else {
                this.view = "audience";
            }
        },
        handleAudienceClick(audience) {
            if (audience.allowed) {
                this.view = "confirmTCs";
                this.activeAudience = audience;
            } else {
                this.view = "usertypeNotAllowed";
            }
        },
        handleClearAll() {
            this.view = "jurisdiction";
            this.activeJurisdiction = undefined;
            this.activeAudience = undefined;
        },
        closeModal() {
            const modal = document.querySelector("#modal");
            modal.classList.add("hidden");
        },
        async handleSignIn() {
            const body = new URLSearchParams({
                csrfmiddlewaretoken,
                audience: this.activeAudience.id,
                remember_me: 30,
                ignore_structural_segments: true,
            });
            // Check if current pathname includes a jurisdiction + audience
            const currentPathnameArr = window.location.pathname
                .split("/")
                .filter((x) => !!x);
            const includesJurisdiction = allJurisdictions
                .map((jr) => jr.urlIdentifier)
                .filter((urlIdentifier) => !!urlIdentifier)
                .some(
                    (jr) =>
                        currentPathnameArr.includes(jr.toLowerCase()) ||
                        currentPathnameArr.includes(jr.toUpperCase()),
                );
            // remove jurisdiction and audience
            const redirectPathnameArr = includesJurisdiction
                ? currentPathnameArr.slice(2)
                : currentPathnameArr;
            // replace with jurisdiction and audience from state
            const redirectPathname = `/${
                this.activeJurisdiction.urlIdentifier
            }/${
                this.activeAudience.usertype.urlIdentifier
            }/${redirectPathnameArr.join("/")}`;

            // construct URL with relevant hash
            const redirectURL = new URL(window.location.href);
            redirectURL.pathname = redirectPathname;

            fetch("/srp/audience/update", {
                method: "POST",
                headers: {
                    "X-CSRFToken": csrfmiddlewaretoken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: body.toString(),
            })
                .then((response) => {
                    if (response.ok) {
                        window.location.href = redirectURL.href;
                    } else {
                        console.error(
                            "Sign-in failed with status code:",
                            response.status,
                        );
                        alert("There has been a problem signing you in");
                    }
                })
                .catch((err) => console.log(err));
        },
    });
}
