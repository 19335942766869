{
    "groups": [
        {
            "header": null,
            "jurisdictions": [
                {
                    "id": 25,
                    "display_name": "United Kingdom",
                    "urlIdentifier": "UK",
                    "audiences": [
                        {
                            "id": 49,
                            "display_name": "UK Professional Client",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": 49,
                            "display_name": "UK Retail Client",
                            "usertype": {
                                "id": 24,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["GB"]
                }
            ]
        },
        {
            "header": null,
            "jurisdictions": [
                {
                    "id": 161,
                    "display_name": "Germany",
                    "urlIdentifier": "de",
                    "audiences": [
                        {
                            "id": 350,
                            "display_name": "German Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["DE"]
                },
                {
                    "id": 163,
                    "display_name": "Ireland",
                    "urlIdentifier": "ie",
                    "audiences": [
                        {
                            "id": 352,
                            "display_name": "Ireland Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["IE"]
                },
                {
                    "id": 164,
                    "display_name": "Luxembourg",
                    "urlIdentifier": "lu",
                    "audiences": [
                        {
                            "id": 353,
                            "display_name": "Luxembourg Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["LU"]
                },
                {
                    "id": 167,
                    "display_name": "Switzerland",
                    "urlIdentifier": "ch",
                    "audiences": [
                        {
                            "id": 356,
                            "display_name": "Switzerland Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["CH"]
                },
                {
                    "id": 435,
                    "display_name": "Italy",
                    "urlIdentifier": "it",
                    "audiences": [
                        {
                            "id": 757,
                            "display_name": "Italy Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["IT"]
                },
                {
                    "id": 632,
                    "display_name": "Austria",
                    "urlIdentifier": "au",
                    "audiences": [
                        {
                            "id": 1020,
                            "display_name": "Austria Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["AU"]
                },
                {
                    "id": 637,
                    "display_name": "Spain",
                    "urlIdentifier": "es",
                    "audiences": [
                        {
                            "id": 1025,
                            "display_name": "Austria Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["ES"]
                }
            ]
        },
        {
            "header": null,
            "jurisdictions": [
                {
                    "id": 170,
                    "display_name": "Denmark",
                    "urlIdentifier": "dk",
                    "audiences": [
                        {
                            "id": 365,
                            "display_name": "Denmark Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["DK"]
                },
                {
                    "id": 162,
                    "display_name": "Iceland",
                    "urlIdentifier": "is",
                    "audiences": [
                        {
                            "id": 351,
                            "display_name": "Iceland Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["IS"]
                },
                {
                    "id": 160,
                    "display_name": "Finland",
                    "urlIdentifier": "fi",
                    "audiences": [
                        {
                            "id": 349,
                            "display_name": "Finland Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["FI"]
                },
                {
                    "id": 165,
                    "display_name": "Norway",
                    "urlIdentifier": "no",
                    "audiences": [
                        {
                            "id": 354,
                            "display_name": "Norway Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["NO"]
                },
                {
                    "id": 166,
                    "display_name": "Sweden",
                    "urlIdentifier": "se",
                    "audiences": [
                        {
                            "id": 355,
                            "display_name": "Sweden Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["SE"]
                },
                {
                    "id": 638,
                    "display_name": "Greece",
                    "urlIdentifier": "gr",
                    "audiences": [
                        {
                            "id": 1026,
                            "display_name": "Greece Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["GR"]
                },
                {
                    "id": 636,
                    "display_name": "Cyprus",
                    "urlIdentifier": "cy",
                    "audiences": [
                        {
                            "id": 1024,
                            "display_name": "Cyprus Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["CY"]
                }
            ]
        },
        {
            "header": null,
            "jurisdictions": [
                {
                    "id": 203,
                    "display_name": "Other Europe",
                    "urlIdentifier": "oeu",
                    "audiences": [
                        {
                            "id": 393,
                            "display_name": "Other Europe",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["EU"]
                }
            ]
        },
        {
            "header": null,
            "jurisdictions": [
                {
                    "id": 269,
                    "display_name": "United States",
                    "audiences": [],
                    "countries": ["US"]
                }
            ]
        },
        {
            "header": null,
            "jurisdictions": [
                {
                    "id": 302,
                    "display_name": "Channel Islands",
                    "urlIdentifier": "je",
                    "audiences": [
                        {
                            "id": 558,
                            "display_name": "Channel Islands Professional Investor",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": ["JE", "GG"]
                }
            ]
        },
        {
            "header": "All Other Countries",
            "jurisdictions": [
                {
                    "id": 168,
                    "display_name": "International",
                    "urlIdentifier": "all",
                    "audiences": [
                        {
                            "id": 459,
                            "display_name": "Professional Client",
                            "usertype": {
                                "id": 25,
                                "display_name": "Professional Client",
                                "urlIdentifier": "Professional-Adviser"
                            },
                            "allowed": true
                        },
                        {
                            "id": -1,
                            "display_name": "Retail Client",
                            "usertype": {
                                "id": -1,
                                "display_name": "Retail Client",
                                "urlIdentifier": "Retail-Client"
                            },
                            "allowed": false
                        }
                    ],
                    "countries": [
                        "AL",
                        "AD",
                        "AM",
                        "AT",
                        "BH",
                        "BE",
                        "BA",
                        "BG",
                        "CA",
                        "HR",
                        "CZ",
                        "EE",
                        "FO",
                        "FR",
                        "GI",
                        "GL",
                        "VA",
                        "HK",
                        "HU",
                        "IM",
                        "IL",
                        "LV",
                        "LI",
                        "LT",
                        "MT",
                        "MC",
                        "ME",
                        "MS",
                        "NL",
                        "NZ",
                        "PL",
                        "PT",
                        "QA",
                        "RO",
                        "RS",
                        "SG",
                        "SK",
                        "SI",
                        "ZA",
                        "KR",
                        "SJ",
                        "AE",
                        "VG"
                    ]
                },
                {
                    "id": -1,
                    "display_name": "Other Not allowed",
                    "audiences": [],
                    "countries": [
                        "AF",
                        "AX",
                        "DZ",
                        "AS",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AW",
                        "AZ",
                        "BS",
                        "BD",
                        "BB",
                        "BY",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BF",
                        "BI",
                        "CV",
                        "KH",
                        "CM",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "CU",
                        "CW",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "SZ",
                        "ET",
                        "FK",
                        "FJ",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "GH",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "HN",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "JM",
                        "JP",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KW",
                        "KG",
                        "LA",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "MO",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MN",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NC",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "KP",
                        "MK",
                        "MP",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PR",
                        "RE",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "SC",
                        "SL",
                        "SX",
                        "SB",
                        "SO",
                        "GS",
                        "SS",
                        "LK",
                        "SD",
                        "SR",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW"
                    ]
                }
            ]
        }
    ],
    "metadata": {}
}
