import Alpine from "alpinejs";

function cookies() {
    const savedPerfPerm = localStorage.getItem("performance");
    const modalOpen = typeof savedPerfPerm !== "string";
    Alpine.store("cookies", {
        necessaryCookies: true,
        performanceCookies: savedPerfPerm === "false" ? false : true,
        showInfo: false,
        activeSection: "necessaryCookies",
        modalOpen,
        toggleModalOpen() {
            this.modalOpen = !this.modalOpen;
        },
        closeModal() {
            this.modalOpen = false;
        },
        saveAndClose() {
            localStorage.setItem("performance", `${this.performanceCookies}`);
            this.gtmEvent();
            this.closeModal();
        },
        setActiveSection(str) {
            this.activeSection = str;
        },
        toggleShowInfo() {
            this.showInfo = !this.showInfo;
        },
        gtmEvent() {
            const perm = this.performanceCookies ? "granted" : "denied";
            if (Array.isArray(window.dataLayer)) {
                window.dataLayer.push({
                    event: "gtm.consent_update",
                    analytics_storage: perm,
                });
            }
        },
        toggleCookies(key) {
            switch (key) {
                case "performanceCookies":
                    this.performanceCookies = !this.performanceCookies;
                    localStorage.setItem(
                        "performance",
                        `${this.performanceCookies}`,
                    );
                    break;
                // case "targetingCookies":
                //     this.targetingCookies = !this.targetingCookies;
                //     localStorage.setItem(
                //         "targeting",
                //         `${this.targetingCookies}`,
                //     );
                //     break;
                default:
                    break;
            }
        },
        data: [
            {
                key: "necessaryCookies",
                title: "Strictly necessary cookies",
                text: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.",
            },
            {
                key: "performanceCookies",
                title: "Performance and targeting cookies",
                text: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance. These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
            },
            // {
            //     key: "targetingCookies",
            //     title: "Targeting cookies",
            //     text: "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
            // },
        ],
    });
}

export default cookies;
